// React & Gatsby
import React from "react"
import Image from "react-bootstrap/Image"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Custom
import Page from "../components/Layout/Page"
import Container from "react-bootstrap/Container";


const BlogTemplate = ({ pageContext }) => {
    const {post} = pageContext;
    return (
        <Page className={`post ${post.frontmatter.slug}`}>
            <Container
                className={'blog-post mt-4 mb-xs-2 px-xs-4'}
            >
                <Row role={'main'} className={'justify-content-center px-xs-2 mt-5 pt-5'}>
                    <Col className={'mb-xs-4'} lg={8}>
                        <Image
                            src={post.frontmatter.image}
                            alt={post.frontmatter.alt}
                            style={{width : "100%"}}
                        />
                        <h1 className={'mb-4 mt-sm-4'}>{ post ? post.frontmatter.title : 'Title Placeholder'}</h1>
                        <p>{ post ? post.frontmatter.subtitle : ''}</p>
                        <div dangerouslySetInnerHTML={{ __html: post.html }} />
                    </Col>
                </Row>
            </Container>
        </Page>
    )
}

export default BlogTemplate;
